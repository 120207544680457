import { initFirebase } from "./utils/fbase"
import { createApp } from 'vue'


import App from "../App.vue"

initFirebase()

document.addEventListener('DOMContentLoaded', () => {
    console.log("DOMContentLoaded")
})

const vueApp = createApp(App)

vueApp.mount('#app')