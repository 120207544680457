<script setup>
console.log("App loaded.")
</script>

<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="mx-auto">
                <h1 class="title has-text-centered">
                    The Best Plush In Canada
                </h1>
                <p class="subtitle has-text-centered">
                    We are the exclusive wholesaler of the Aurora World plush in the Canada.
                </p>
                <div class="buttons is-centered">
                    <button class="button is-primary">View Products</button>
                    <button class="button is-primary is-light">View Catalogues</button>
                </div>
            </div>
        </div>
    </section>
</template>